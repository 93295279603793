.component-date-selector {
    background-color: white;
    grid-column: 1 / 4;
    grid-row : 1 / 2;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding : 20px;
    gap : 20px;
    margin : 10px

}

.component-date-selector-with-insights {
    height: 100%;
    background-color: white;
    grid-column: 2 / 4;
    grid-row : 1 / 2;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding : 5px

}

@media (max-width : 767px) {
    .component-date-selector-with-insights {
        grid-column : 1 / 2;
        grid-row : 2 / 3
    }
}