.svg-icons {
    pointer-events: none;
}

.super-contacts-list {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.super-contacts-row {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
}

.super-contacts-content {
    display: flex;
    flex-direction: row;
    gap : 10px;
    justify-content: space-between;
    align-items: flex-start;
    padding : 5px;
    width: 100%;
}

.super-contacts-row:hover{
    background-color: rgba(128, 128, 128, 0.274);
}