/* const pieChartColors = [
    "#A789EA",
    "#303279",
    "#4F4794",
    "#7E6BC6"
  ] */

table {
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
  }
  th {
    background-color: #f2f2f2;
  }
  th, td {
    padding: 10px;
    border: 1px solid #f2f2f2;
  }

  tr:nth-child(odd){
    background-color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 25px;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 25px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 25px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 25px;
  }