.st-container {
    background-color: white;
    display : grid;
    grid-template-columns : 15% 1fr;
    grid-template-rows : 100px 1fr
}

.st-row {
    background-color: rgb(238,238,238);
}

.st-sidebar-container{
    height: 100%;
    background-color: #202453;
    grid-column: 1 / 2 ;
    grid-row : 1 / 3
}

.st-navbar-container {
    height: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #202453;
}

.st-navbar {
    background-color: #202453;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding : 10px
}

.st-content-container {
    height: 100%;
    grid-column: 2 / 3;
    grid-row: 2 /3;
    background-color: rgb(238,238,238);
}