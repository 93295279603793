.imgdiv img{
    border-radius: 50px;
}
.ullogin li{
list-style: none;
color: black;
}
.ullogin{
    display: flex;
    justify-content: center;
}
.submitbtn{
    background: linear-gradient(90deg, #D15083 3.68%, #D9696B 52%, #E46A48);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
      
}
.box{
        /* background: linear-gradient(90deg, #4b3554 3.68%, #e49bff 52%, #e5affc); */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.rightcol{
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* padding: 5rem; */
    /* border-radius: 20px; */
}