/* App.css */
.left-column {
  padding: 20px;
  height: auto;
}

.right-column {
  padding: 20px;
}

.cust-form-container {
    
  background-color: white;
    /* background-color: rgba(255, 255, 255, 0.1); Adjust the alpha value (0 to 1) for the desired level of transparency */
  color: black;
  border-radius: 25px;
  padding: 30px;
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  margin-top: 10px;
}
