.not-exists {
    color: #71130C;
}

.exists {
    color : blue
}

.imports-card {
    border-radius : 7px
}