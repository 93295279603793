.nav-link-icon{
    color: rgb(227, 131, 22) !important;
    margin: 6px 0;
    text-decoration: none;
    font-weight: bold;
}
.nav-icon{
    color: rgb(227, 131, 22) !important;
   
    
}
.togglebtn{
    color: white
}

.sublink{
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    color: gainsboro;
}
.sublink:hover{
    color: rgb(141, 133, 125);
}