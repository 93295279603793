.popover-container {
    position: fixed;
    top : 0;
    right : 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(128, 128, 128, 0.352);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 10px;
    padding: 20px;
}

.popover-inner {
    background-color: white;
    width: 100%;
    border-radius: 10px;
}

