.selectButton {
    background-color: green;
    color: white;
    border-radius: 25px;
    min-height: fit-content;
    height: 1.0cm;
    max-height: 3cm;
}

#container {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20vh;
    align-items: center;
    justify-items: center;
  }

.email-card {
    background-color: white;
    border: 1px solid #bacdd8;
    padding: 8px;
    border-radius: 12px;
}


.emails-form-container {
    
  background-color: white;
    /* background-color: rgba(255, 255, 255, 0.1); Adjust the alpha value (0 to 1) for the desired level of transparency */
  color: black;
  border-radius: 25px;
  padding: 30px;
}
.left-column {
  padding: 20px;
  /* height: auto; */
}

.right-column {
  padding: 20px;
}

.box-container {
    
  background-color: #f4f4f4;
    /* background-color: rgba(255, 255, 255, 0.1);  */
color: black;
  border-radius: 8px;
  padding: 30px;
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  margin-top: 10px;
}
