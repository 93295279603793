.selectButton {
    background-color: green;
    color: white;
    border-radius: 25px;
    min-height: fit-content;
    height: 1.0cm;
    max-height: 3cm;
}

#container {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20vh;
    align-items: center;
    justify-items: center;
  }

.chats-card {
    background-color: white;
    border: 1px solid #bacdd8;
    padding: 8px;
    border-radius: 12px;
}



.left-column {
  padding: 20px;
  /* height: auto; */
}

.right-column {
  padding: 20px;
}

.box-container {
    
  background-color: white !important;
    /* background-color: rgba(255, 255, 255, 0.1);  */
color: black;
  border-radius: 8px;
  padding: 30px;
  /* border: 1px solid rgb(29, 29, 75); */
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  margin-top: 10px;
}
