.selectButton {
    background-color: green;
    color: white;
    border-radius: 25px;
    min-height: fit-content;
    height: 1.0cm;
    max-height: 3cm;
}

#container {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20vh;
    align-items: center;
    justify-items: center;
  }

.home-card {
    background-color: white;
    border: 1px solid #bacdd8;
    padding: 8px;
    border-radius: 12px;
}


.form-container {
    
  background-color: #f4f4f4;
    /* background-color: rgba(255, 255, 255, 0.1); Adjust the alpha value (0 to 1) for the desired level of transparency */
color: black;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid black;
}
.left-column {
  padding: 20px;
  /* height: auto; */
}

.right-column {
  padding: 20px;
}

.box-container {
    
  background-color: #f4f4f4;
    /* background-color: rgba(255, 255, 255, 0.1);  */
color: black;
  border-radius: 8px;
  padding: 30px;
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  margin-top: 10px;
}

.homeContent {
  height : 100%;
  width : 100%;
  background-color: #151936;

}

.homeComponentContainer {
  height : 100%;
  background-color:rgb(238, 238, 238);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 100px max-content;
  gap : 10px;
  padding: 10px;
}

.homeSubComponentContainer1 {
  height: 100%;
  width: 100%;
  background-color: white;
  grid-column: 1 / 2;
  grid-row: 1 / 2 ;
  border-radius: 25px;
  padding: 15px;
}

.homeSubComponentContainer2 {
  height: auto;
  background-color: white;
  border-radius: 25px;
  padding : 10px
}

.fileSelector {
  background-color: white;
  display: flex;
  direction: row;
  justify-content: space-between;
  margin: 15px;
}

.selectButton {
  background-color: green;
  color: white;
  border-radius: 25px;
  min-height: fit-content;
  height: 1.0cm;
  max-height: 3cm;
}


.home-card {
  background-color: white;
  border: 1px solid #bacdd8;
  padding: 8px;
  border-radius: 12px;
}

.tableheader {
  display : flex;
  direction: row;
  justify-content: space-around;
}