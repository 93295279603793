.verb-table-container {
    border-radius: 25px;
    background-color: white;
    padding : 10px;
    margin : 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: visible;
}

.verb-table{
    overflow: visible;
}
