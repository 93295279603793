/* styles.css */

/* Sidebar */
.sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  background-color: aliceblue ;
}

/* Main Content */
.main-content {
  padding-top: 20px;
}
/* styles.css */

/* Main Content */
.main-content {
  padding-top: 20px; /* Add padding as needed */
}

/* Adjust the width based on your design */
.main-content {
  width: calc(100% - 250px); /* Assuming sidebar width is 250px */
  margin-left: 250px; /* Assuming sidebar width is 250px */
  background-color: aquamarine;
}

/* Add media query for responsiveness */
@media (max-width: 768px) {
  .main-content {
    width: 100%;
    margin-left: 0;
  }
}


/* Header */
/* Header.css */

.header-container {
  background-color: #f8f9fa; /* Set your desired background color */
  padding: 20px;
}

/* .jumbotron {
  background-color: #f8f9fa;
  padding: 20px;
} */

/* Table Component */
.table {
  margin-top: 20px;
}

/* Pie Chart Component */
.pie-chart-container {
  text-align: center;
}

/* Graph Component */
.graph-container {
  margin-top: 20px;
}

/* Box Component */
.card {
  margin-top: 20px;
}
