.insightContainer {
    height: 100%;
    width: 100%;
    background-color: #202453;
}

.insightSidebar {
    height: 100%;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding-left: 20px;
    background-color: #202453;
}

.insightNavbar {
    height: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-left: 20px;
}

.insightContent {
    height: 100%;
    background-color: #151936
}

.componentContainer {
    display: grid;
    height: 100%;
    background-color: rgb(238, 238, 238);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 125px 1fr 1fr;
    gap : 5px
}

.subComponentContainer1 {
    height: 100%;
    background-color:rgb(238, 238, 238);
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap : 15px;
    padding : 5px
}

.subComponentContainer2 {
    height: 100%;
    background-color: rgb(238, 238, 238);
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    display: grid;
    column-gap : 15px;
    padding : 5px;
    grid-template-columns: 1fr 1fr 1fr;
}

.subComponentContainer3{
    height: 100%;
    background-color: rgb(238, 238, 238);
    grid-column: 1 / 4;
    grid-row: 3 / 4;
    display: grid;
    column-gap : 15px;
    padding : 5px;
    grid-template-columns: 1fr 1fr 1fr;
}

.component-total-calls {
    height: 100%;
    background-color: white;
    grid-column: 1 / 2;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding-left: 10px;

}


.component-call-status {
    height: 100%;
    background-color: white;
    grid-column: 1 / 2;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
}

.component-calls-per-weekday {
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    grid-column: 2 / 3;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
    border: 1px solid #BBBBBB;
}

.component-call-length {
    height: 100%;
    background-color: white;
    grid-column: 1 / 2;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
}

.component-calls-per-hour-of-day {
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    grid-column: 2 / 3;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
    border: 1px solid #BBBBBB;
}


@media (max-width:767px) {

    .insightContainer{
        grid-template-columns: 100px 1fr;
        grid-template-rows: 50px 1fr;
    }

    .componentContainer{
        grid-template-columns: 1fr;
        grid-template-rows : 1fr 1fr 1fr
    }

    .subComponentContainer1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        grid-template-columns: 1fr;
        grid-template-rows : repeat(3,1fr);
        row-gap: 10px;
    }

    .subComponentContainer2 {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        grid-template-columns: 1fr;
        grid-template-rows : repeat(3,1fr);
        row-gap: 10px;
    }

    .subComponentContainer3 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        grid-template-columns: 1fr;
        grid-template-rows : repeat(3,1fr);
        row-gap: 10px
    }

    .component-total-calls {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    

    }

    .component-call-status {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .component-calls-per-weekday {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .component-call-length {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .component-calls-per-hour-of-day {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }


}


