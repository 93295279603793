.cu-container{
    height : 100%;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: rgb(238,238,238);
   
}


.cu-form-container {
    height: auto;
    background-color: white;
    border-radius: 25px;
    padding : 20px;
    margin : 0 auto;
    display: flex;
    flex-direction: column;
    gap : 50px;
    width: 400px;
}

.cu-form-header {
    border-bottom: 2px solid gray;
}

.cu-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap : 10px
}

.cu-email {
    border-radius: 5px;
    border : 1px solid gray;
    
}

.cu-label {
    color: gray;
    font-style: italic;
}